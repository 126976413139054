import { UseForecastDetailsServiceProps } from '../../';

export type BuildUrlProps = Omit<
  UseForecastDetailsServiceProps,
  'accountId' | 'vendorId' | 'setPagination'
>;

export const buildUrl = ({
  period,
  status,
  page,
  pageSize,
  type,
  region,
  sort,
  search,
  forecastId,
}: BuildUrlProps) => {
  let url = `api/link-collab-forecast-bff/forecast/page?period=${period}&status=${status}&page=${page}&size=${pageSize}&forecastId=${forecastId}`;

  if (type) {
    url += `&type=${type}`;
  }

  if (region) {
    url += `&region=${region}`;
  }

  if (sort.prop && sort.direction) {
    url += `&sort=${sort.prop},${sort.direction.toLowerCase()}`;
  }

  if (search) {
    url += `&search=${search}`;
    url = url.replace(`&page=${page}`, `&page=0`);
  }

  return url;
};
